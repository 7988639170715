<template>
    <LayoutNext>
        <template #page-title>
            Update User Account
        </template>
        <nav aria-label="breadcrumb" style="font-size: 13px;">
            <MDBBreadcrumb>
                <MDBBreadcrumbItem><router-link to="/users">Users</router-link>
                </MDBBreadcrumbItem>
                <MDBBreadcrumbItem active style="cursor: default;">
                    Update User Account
                </MDBBreadcrumbItem>
            </MDBBreadcrumb>
        </nav>
        <div class="backdrop" v-if="loadingSpinner">
            <div class="image-container d-flex justify-content-center align-items-center">
                <div class="loader"></div>
                <img src="@/assets/cs-loader.png" alt="Loading image">
            </div>
        </div>
        <div class="d-md-flex d-block gap-0 gap-md-3 my-auto">
            <MDBCard id="UserInformation" class="shadow m-auto mb-md-0 mb-3 account-form" tag="form">
                <MDBCardHeader class="py-3">
                    <h5 class="mb-0 fw-bold">Account Information</h5>
                </MDBCardHeader>
                <MDBCardBody class="d-flex flex-column" style="gap: 15px">
                    <div class="d-flex justify-content-center align-items-center mb-2">
                        <div class="default-avatar shadow-lg me-1" v-if="!usersStore.users.profilePictureUrl">
                            <span class="avatar-letter">{{ avatarLetter }}</span>
                        </div>
                        <img v-else :src="usersStore.users.profilePictureUrl" alt="Profile Picture"
                            class="profile-avatar shadow-lg me-1" />
                    </div>
                    <div class="d-flex gap-3">
                        <div class="d-block w-100">
                            <label for="firstName">First Name:</label>
                            <MDBInput id="firstName" class="mt-1" type="text" placeholder="First Name"
                                v-model="usersStore.users.firstName" />
                        </div>
                        <div class="d-block w-100">
                            <label for="lastName">Last Name:</label>
                            <MDBInput id="lastName" class="mt-1" type="text" placeholder="Last Name"
                                v-model="usersStore.users.lastName" />
                        </div>
                    </div>
                    <div>
                        Email:
                        <MDBInput class="mt-1" type="email" placeholder="Email" v-model="usersStore.users.email" />
                    </div>
                    <div>
                        Partner Percentage:
                        <MDBInput class="mt-1" type="number" placeholder="Partner Percentage"
                            v-model="usersStore.users.partnerPercentage" />
                    </div>
                    <div v-if="isUser">
                        CS Code:
                        <MDBInput class="mt-1" type="text" placeholder="CS Code" v-model="usersStore.users.csCode" />
                    </div>
                    <div class="mt-1">
                        <MDBSwitch label="Is Distributor" v-model="usersStore.users.isDistributor" />
                    </div>
                    <div v-if="usersStore.users.isDistributor">
                        Google Sheet Info:
                        <MDBInput label="Google Sheet Link" type="text" v-model="usersStore.users.googleSheetLink"
                            class="mt-4" />
                        <MDBInput label="Google Sheet's SheetName" type="text" v-model="usersStore.users.sheetName"
                            class="mt-4" />
                    </div>
                    <div class="mt-2">
                        <MDBBtn class="fw-bold" type="button" color="primary" aria-controls="assetLabelModal"
                            @click="assetLabelModal = true" size="sm">
                            Select Asset Label/s
                        </MDBBtn>
                        <MDBTable hover class="mt-3">
                            <thead class="table-light align-middle">
                                <tr>
                                    <th v-for="(item, i) in tableHeaders" :key="i" scope="col" class="py-3 px-4">
                                        {{ item.header }}
                                    </th>
                                </tr>
                            </thead>
                            <tbody v-if="usersStore.assetLabels.length < 1">
                                <tr class="align-middle">
                                    <td colspan="3">
                                        No asset label selected.
                                    </td>
                                </tr>
                            </tbody>
                            <tbody v-else-if="usersStore.assetLabels.length > 0">
                                <tr v-for="(item, i) in usersStore.assetLabels" :key="i">
                                    <td class="align-middle">{{ item.name }}</td>
                                    <td class="align-middle" style="width: 45%;">
                                        <select v-model="item.assignedDashboard" class="form-select">
                                            <option v-for="option in dashboard" :key="option.value"
                                                :value="option.value">
                                                {{ option.text }}
                                            </option>
                                        </select>
                                    </td>
                                    <td class="d-flex justify-content-end text-right p-4">
                                        <MDBBtn color="danger" floating @click.stop.prevent="clickDelete(item)"
                                            size="sm">
                                            <span class="far fa-trash-alt" />
                                        </MDBBtn>
                                    </td>
                                </tr>
                            </tbody>
                        </MDBTable>
                    </div>
                </MDBCardBody>
                <MDBCardFooter class="py-3 d-flex justify-content-end">
                    <MDBBtn class="fw-bold" type="submit" @click="submit" color="primary"
                        :disabled="isUpdateBtnDisabled" size="sm">
                        Update Account
                    </MDBBtn>
                </MDBCardFooter>
            </MDBCard>
            <MDBCard class="shadow account-form" v-if="isUser">
                <MDBCardHeader class="py-3">
                    <h5 class="mb-0 fw-bold text-center">Protected Content</h5>
                </MDBCardHeader>
                <MDBCardBody>
                    <MDBTable borderless style="table-layout: fixed;">
                        <tbody v-for="media in socialMedia" :key="media.value">
                            <tr>
                                <td class="py-3 ps-0 align-middle social-title">
                                    <div class="d-flex gap-2 align-items-center">
                                        <template v-if="media.value == 8">
                                            <img src="@/assets/kuashou-icon.png" alt="Kuaishou"
                                                style="width: 16px; height: 16px;" />
                                        </template>
                                        <template v-else-if="media.value == 9">
                                            <img src="@/assets/rumble-icon.png" alt="Rumble"
                                                style="width: 16px; height: 16px;" />
                                        </template>
                                        <MDBIcon v-else :icon="media.icon" :iconStyle="media.iconStyle"
                                            class="fa-md pt-1" style="width: 20px; height: 20px; text-align: center;" />
                                        <label :for="`${media.text}Link`" class="fw-bold">{{ media.text }}</label>
                                    </div>
                                </td>
                                <td class="py-3 pe-0 align-middle social-link">
                                    <span class="text-truncate d-inline-block align-middle"
                                        v-if="socialMediaLinks[media.value]"
                                        style="max-width:100%; overflow: hidden; white-space: nowrap; text-overflow: ellipsis;">
                                        <a :href="`//${socialMediaLinks[media.value]}`"
                                            :title="socialMediaLinks[media.value]" target="_blank">
                                            {{ socialMediaLinks[media.value] || 'page url' }}
                                        </a>
                                    </span>
                                    <span v-else>No link provided.</span>
                                </td>
                            </tr>
                        </tbody>
                    </MDBTable>
                </MDBCardBody>
                <MDBCardFooter class="d-flex justify-content-center">
                    <MDBBtn class="fw-bold" size="sm" color="primary" @click="toggleContentModal">
                        <MDBIcon iconStyle="fas" icon="plus" class="me-1" />
                        Add more content
                    </MDBBtn>
                </MDBCardFooter>
            </MDBCard>
        </div>
        <MDBModal id="assetLabelModal" tabindex="-1" labelledby="assetLabelModalLabel" v-model="assetLabelModal"
            scrollable :static-backdrop="true">
            <MDBModalHeader class="px-4">
                <MDBModalTitle class="fw-bold" id="assetLabelModalLabel"> Asset Labels </MDBModalTitle>
            </MDBModalHeader>
            <MDBModalBody class="pb-0">
                <div>
                    <MDBInput label="Seach Asset Label" class="mb-4" v-model="dataTableSearch" />
                    <MDBDatatable :dataset="usersStore.assetLabelDataTable" selectable multi :search="dataTableSearch"
                        @selected-rows="handleSelectedRow($event)" hover />
                </div>
            </MDBModalBody>
            <MDBModalFooter class="gap-2 py-3 px-4">
                <MDBBtn class="fw-bold" size="sm" @click="assetLabelModal = false"> Close </MDBBtn>
                <MDBBtn class="fw-bold" size="sm" color="primary" @click="assetLabelModal = false">
                    Select Asset Labels
                </MDBBtn>
            </MDBModalFooter>
        </MDBModal>
        <MDBModal id="contentModal" tabindex="-1" labelledby="contentModalLabel" v-model="contentModal" scrollable
            size="lg" :static-backdrop="true">
            <MDBModalHeader class="px-4 py-3">
                <MDBModalTitle class="fw-bold" id="contentModalLabel"> Add Protected Content </MDBModalTitle>
            </MDBModalHeader>
            <MDBModalBody class="px-4 py-3 pb-2">
                <div v-for="media in socialMedia" :key="media.value"
                    :class="['input-group w-100 mb-3', { 'align-items-start': media.value === 10 }]">
                    <MDBBtn color="tertiary" size="sm" class="pb-2 button-title"
                        @click="selectedSocialMedia = media.value">
                        <template v-if="media.value == 8">
                            <img src="@/assets/kuashou-icon.png" alt="Kuaishou"
                                style="width: 16px; height: 16px; margin-right:12px;" />
                        </template>
                        <template v-else-if="media.value == 9">
                            <img src="@/assets/rumble-icon.png" alt="Rumble"
                                style="width: 16px; height: 16px; margin-right:12px;" />
                        </template>
                        <MDBIcon v-else :icon="media.icon" :iconStyle="media.iconStyle" :style="media.marginRight"
                            class="fa-md pt-1" style="width: 20px; height: 20px; text-align: center;" />
                        <label :for="media.text" class="fw-bold" style="padding-bottom: 1px">{{ media.text }}</label>
                    </MDBBtn>
                    <template v-if="media.value == 10">
                        <textarea name="rumble" id="rumble" class="form-control" v-model="socialMediaLinks[10]"
                            placeholder="(Add links to Google Drive, OneDrive, DropBox, etc.)"
                            style="height: 100px;margin-left: 1px;margin-top: 1px" />
                    </template>
                    <input v-else type="text" class="form-control" v-model="socialMediaLinks[media.value]"
                        :placeholder="`Enter ${media.text} URL`" style="margin-left: 1px;margin-top: 1px" />
                </div>
            </MDBModalBody>
            <MDBModalFooter class="d-flex gap-2 px-4 py-3">
                <MDBBtn class="fw-bold" size="sm" @click="toggleContentModal"> Close </MDBBtn>
                <MDBBtn class="fw-bold" size="sm" color="primary" @click="submitContent">
                    Update
                </MDBBtn>
            </MDBModalFooter>
        </MDBModal>
        <MDBToast v-model="toastObject.state" :delay="2000" autohide position="top-right" appendToBody stacking
            width="350px" :color="toastObject.color" text="white" :icon="toastObject.icon">
            <template #title>
                {{ toastObject.title }}
            </template>
            {{ toastObject.message }}
        </MDBToast>
    </LayoutNext>
</template>

<script setup>
import { MDBBtn, MDBCard, MDBCardHeader, MDBCardBody, MDBCardFooter, MDBModal, MDBModalHeader, MDBModalBody, MDBModalTitle, MDBModalFooter, MDBDatatable, MDBInput, MDBSwitch, MDBTable, MDBBreadcrumb, MDBBreadcrumbItem, MDBIcon, MDBToast } from "mdb-vue-ui-kit";
import LayoutNext from "@/views/v3/LayoutNext.vue";
import { onMounted, ref, watch, computed } from "vue";
import axios from "axios";
import { useUsersStore } from "@/store/usersStore";
import { useRoute } from "vue-router";
import { useTitle } from "@vueuse/core";
import { storeToRefs } from "pinia";

useTitle("Update User Account | Creator Shield");

const usersStore = useUsersStore();
const { isUser } = storeToRefs(usersStore);
const route = useRoute();
const userId = route.params.id;
const assetLabelModal = ref(false);

const tableLoader = ref(false);
const dataTableSearch = ref("");

onMounted(async () => {
    tableLoader.value = true;
    await axios
        .get("api/assetLabels/Dropdown")
        .then(function (data) {
            usersStore.assetLabelDataTable.rows = data.data;
            usersStore.getUsers({ UserId: userId });
            usersStore.getAssetLabels({ UserId: userId });
            tableLoader.value = false;
        })
        .catch(function (error) {
            toastObject.value.state = true;
            toastObject.value.message = "An error occurred during request" + error;
            toastObject.value.color = "danger";
            toastObject.value.icon = "fas fa-exclamation-circle fa-lg me-2";
        });
});

const handleSelectedRow = (selectedModalArray) => {
    const newArray = [
        ...new Set([...usersStore.assetLabels, ...selectedModalArray]),
    ];
    usersStore.assetLabels = newArray;
};

const clickDelete = (item) => {
    const newArray = usersStore.assetLabels.filter(
        (element) => element.id != item.id
    );
    usersStore.assetLabels = newArray;
};

const toastObject = ref({
    state: false,
    message: "",
    color: "",
    icon: "",
    title: "",
});

let loadingSpinner = ref(false);

const selectedAssetLabels = ref("");
watch([selectedAssetLabels], ([newSelectedAssetLabels]) => {
    usersStore.assetLabels = newSelectedAssetLabels.split(",");
});

const tableHeaders = computed(() => [
    {
        header: "Name",
    },
    {
        header: "Dashboard",
    },
    {},
]);

const isUpdateBtnDisabled = computed(() => usersStore.users.email == "");

const submit = () => {
    event.preventDefault();
    const formData = {
        firstName: usersStore.users.firstName,
        lastName: usersStore.users.lastName,
        email: usersStore.users.email,
        userTypeId: getUserTypeId.value,
        googleSheetLink: usersStore.users.isDistributor ? usersStore.users.googleSheetLink : null,
        assignedAssetLabels: usersStore.assetLabels.map(label => ({
            ...label,
            assignedDashboard: label.assignedDashboard
        })),
        partnerPercentage: usersStore.users.partnerPercentage,
        id: usersStore.users.id,
        sheetName: usersStore.users.isDistributor ? usersStore.users.sheetName : null,
        csCode: usersStore.users.csCode,
    };

    loadingSpinner.value = true;
    axios
        .put("api/users", formData)
        .then(function (response) {
            if (response.status == 200 || response.status == 201) {
                toastObject.value.title = "Success!";
                toastObject.value.state = true;
                toastObject.value.message = "Account information updated successfully!";
                toastObject.value.color = "success";
                toastObject.value.icon = "fas fa-check fa-lg me-2";
                loadingSpinner.value = false;
            } else {
                toastObject.value.title = "Error!";
                toastObject.value.state = true;
                toastObject.value.message = "An error occurred during request.";
                toastObject.value.color = "danger";
                toastObject.value.icon = "fas fa-exclamation-circle fa-lg me-2";
                loadingSpinner.value = false;
            }
        })
        .catch(function (error) {
            toastObject.value.title = "Error!";
            toastObject.value.state = true;
            toastObject.value.message = "An error occurred during request: " + error;
            toastObject.value.color = "danger";
            toastObject.value.icon = "fas fa-exclamation-circle fa-lg me-2";
            loadingSpinner.value = false;
        });
};

// const getUserTypeId = computed(() => (usersStore.users.isDistributor ? 3 : 2));
const getUserTypeId = computed(() => {
    if (usersStore.users.userTypeId == 4) {
        return 4;
    } else if (usersStore.users.userTypeId == 1) {
        return 1;
    } else {
        return usersStore.users.isDistributor ? 3 : 2;
    }
});

const dashboard = ref([
    { text: 'Creator Shield & Very Viral Videos', value: 1 },
    { text: 'Creator Shield', value: 2 },
    { text: 'Very Viral Videos', value: 3 },
]);

const contentModal = ref(false);
const toggleContentModal = () => {
    contentModal.value = !contentModal.value;
};

const newSocialMediaLinks = ref({});
watch(contentModal, (newVal) => {
    if (newVal) {
        newSocialMediaLinks.value = socialMediaLinks.value;
    }
});

const selectedSocialMedia = ref(1);
const socialMediaLinks = ref({
    1: usersStore.users.youtube,
    2: usersStore.users.twitch,
    3: usersStore.users.instagram,
    4: usersStore.users.tiktok,
    5: usersStore.users.facebook,
    6: usersStore.users.twitter,
    7: usersStore.users.douyin,
    8: usersStore.users.kuaishou,
    9: usersStore.users.rumble,
    10: usersStore.users.others,
});

watch(() => usersStore.users, (userSocialLinks) => {
    if (userSocialLinks) {
        socialMediaLinks.value = {
            1: userSocialLinks.youtube,
            2: userSocialLinks.twitch,
            3: userSocialLinks.instagram,
            4: userSocialLinks.tiktok,
            5: userSocialLinks.facebook,
            6: userSocialLinks.twitter,
            7: userSocialLinks.douyin,
            8: userSocialLinks.kuaishou,
            9: userSocialLinks.rumble,
            10: userSocialLinks.others,
        };
    }
});

const socialMedia = ref([
    { text: "YouTube", value: 1, icon: "youtube", iconStyle: "fab", marginRight: 'margin-right: 8px' },
    { text: "Twitch TV", value: 2, icon: "twitch", iconStyle: "fab", marginRight: 'margin-right: 8px' },
    { text: "Instagram", value: 3, icon: "instagram", iconStyle: "fab", marginRight: 'margin-right: 15px' },
    { text: "TikTok", value: 4, icon: "tiktok", iconStyle: "fab", marginRight: 'margin-right: 8px' },
    { text: "Facebook", value: 5, icon: "facebook", iconStyle: "fab", marginRight: 'margin-right: 13px' },
    { text: "Twitter/X", value: 6, icon: "x-twitter", iconStyle: "fa-brands", marginRight: 'margin-right: 8px' },
    { text: "Douyin", value: 7, icon: "tiktok", iconStyle: "fab", marginRight: 'margin-right: 8px' },
    { text: "Kuaishou", value: 8, icon: "youtube", iconStyle: "fab", marginRight: 'margin-right: 12px' },
    { text: "Rumble", value: 9, icon: "play", iconStyle: "fas", marginRight: 'margin-right: 12px' },
    { text: "Others", value: 10, icon: "globe", iconStyle: "fas", marginRight: 'margin-right: 8px' },
]);

const submitContent = async () => {
    const input = {
        userId: userId,
        youtube: newSocialMediaLinks.value[1],
        twitch: newSocialMediaLinks.value[2],
        instagram: newSocialMediaLinks.value[3],
        tiktok: newSocialMediaLinks.value[4],
        facebook: newSocialMediaLinks.value[5],
        twitter: newSocialMediaLinks.value[6],
        douyin: newSocialMediaLinks.value[7],
        kuaishou: newSocialMediaLinks.value[8],
        rumble: newSocialMediaLinks.value[9],
        others: newSocialMediaLinks.value[10],
    };

    loadingSpinner.value = true;

    try {
        const response = await axios.put('/api/UserSocialMediaPages', input);

        if (response.status == 200 || response.status == 201) {
            toastObject.value = {
                title: "Success!",
                state: true,
                message: "Social media links updated successfully!",
                color: "success",
                icon: "fas fa-check fa-lg me-2"
            };
            loadingSpinner.value = false;
            contentModal.value = false;
            usersStore.getUsers({ UserId: userId });
        } else {
            toastObject.value = {
                title: "Error!",
                state: true,
                message: "Failed to update social media links.",
                color: "danger",
                icon: "fas fa-exclamation-circle fa-lg me-2"
            };
            loadingSpinner.value = false;
        }
    } catch (error) {
        toastObject.value = {
            title: "Error!",
            state: true,
            message: "An error occurred: " + error.message,
            color: "danger",
            icon: "fas fa-exclamation-circle fa-lg me-2"
        };
        loadingSpinner.value = false;
    }
};

const avatarLetter = computed(() => {
    return usersStore.users?.firstName?.charAt(0).toUpperCase() || '';
});
</script>

<style>
:root {
    --white: #ffffff;
    --primary: #fc5818;
    --secondary: #e2e6ef;
    --accent: #02061b;
}
</style>

<style scoped>
.btn-primary {
    background-color: var(--primary);
}

.btn-tertiary {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    color: var(--accent);
    border-color: var(--accent);
}

.card,
.card-body {
    overflow-y: auto;
}

.image-container {
    position: relative;
    display: inline-block;
}

.image-container .loader {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    width: 50px;
    height: 50px;
    border: 3px solid rgba(0, 0, 0, 0.1);
    border-top-color: rgb(153, 153, 153);
    border-radius: 50%;
    animation: BorderSpin 1s linear infinite;
}

.image-container img {
    display: block;
    width: 50px;
    height: 50px;
    border-radius: 50%;
}

@keyframes BorderSpin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
}

.account-form {
    width: 50%
}

.button-title {
    width: 17%;
}

.social-title {
    width: 25%;
}

.social-link {
    width: 75%;
}

@media screen and (max-width: 767px) {
    .button-title {
        width: fit-content;
    }

    .account-form {
        width: 100%
    }

    .social-title {
        width: 45%;
    }

    .social-link {
        width: 55%;
    }
}

.default-avatar {
    width: 60px;
    height: 60px;
    background-color: var(--primary);
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    font-size: 30px;
    font-weight: bold;
}

.profile-avatar {
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    object-fit: cover;
}

.avatar-letter {
    text-transform: uppercase;
}
</style>
